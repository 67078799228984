<template>
  <div>
    <el-dialog
      :title="$t('All.退料')"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose" append-to-body>
      <el-form ref="elForm" :model="MaterialData" size="medium" label-width="90px"
      label-position="right">
      <el-form-item :label="$t('All.品名')">
        {{MaterialData.Material_name}}
      </el-form-item>
      <el-form-item :label="$t('All.规格')">
        {{MaterialData.Specifications}}
      </el-form-item>
      <el-form-item :label="$t('All.供应商')">
        {{MaterialData.Supplier}}
      </el-form-item>
      <el-form-item :label="$t('All.进料日期')">
        {{MaterialData.Feeding_date}}
      </el-form-item>
      <el-form-item :label="$t('All.负责人')">
        {{MaterialData.Person}}
      </el-form-item>
      <el-form-item label="LOT NO:">
        {{MaterialData.Lot_no}}
      </el-form-item>
      <el-form-item :label="$t('All.剩余数量')">
        {{MaterialData.Quantity}}
      </el-form-item>
      <el-form-item :label="$t('All.已退数量')">
        {{MaterialData.TQuantity}}
      </el-form-item>
      <el-form-item :label="$t('All.退料数量')">
        <el-input v-model="Quantity" :placeholder="$t('All.请输入退料数量')" onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.打印机')">
          <el-select :style="{width: '100%'}" v-model="Printname" value-key="Id" :placeholder="$t('All.请选择打印机')">
            <el-option
              v-for="item in this.$store.state.PrinterData"
              :key="item.Name"
              :label="item.Wz"
              :value="item">
          </el-option>
          </el-select>
        </el-form-item>
      <el-form-item style="margin: 0 auto;text-align: center;" size="large">
        <el-button type="primary" @click="Tuiliao()">{{ $t('All.提交') }}</el-button>
        <el-button @click="Iscoder('')">{{ $t('All.取消') }}</el-button>
      </el-form-item>
    </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dialogVisible: true,
      Quantity: '',
      Printname: ''
    }
  },
  props: ['Iscoder', 'MaterialData', 'GetMaterial'], // 接手psMsg值
  created () {
  },
  mounted () {

  },
  methods: {
    handleClose (done) {
      this.$confirm(this.$t('All.确认取消退料'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          this.Iscoder('')
        })
        .catch(_ => {})
    },
    async Tuiliao () {
      if (this.MaterialData.TQuantity === null || this.MaterialData.TQuantity === '') {
        if (parseFloat(this.Quantity) > parseFloat(this.MaterialData.Quantity)) return this.$message.error(this.$t('All.退料不可大于物料原有量'))
      } else {
        if (parseFloat(this.Quantity) > parseFloat(this.MaterialData.Quantity) + parseFloat(this.MaterialData.TQuantity)) return this.$message.error(this.$t('All.退料不可大于物料原有量'))
      }
      const tuidata = {
        Material: this.MaterialData,
        PrinterData: this.Printname,
        Quantity: this.Quantity,
        UserData: this.$store.state.Login
      }
      const { data: res } = await this.$http.post('/api/Semifinished/TuiMaterial', tuidata)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.formData = res.response
      this.formData = { ...this.formData }
      this.$message.success(res.msg)
      this.GetMaterial(this.MaterialData.Order_no)
      this.Iscoder('')
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: fixed;
  height: 100%;
  background-color: rgba(255, 255, 255);
  min-width: 903px;
  width: 100%;
}
  .el-col {
    border: 1px solid;
    margin-right: -1px;
     margin-bottom: -1px;
    line-height: 4.5vw;
    font-size: 2vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .grid-content {
    min-height: 36px;
  }
  .bg-purple-light{

  }
  .row-bg {
    background-color: #f9fafc;
  }
table .td{
  border: none;
}
</style>
